const data = [
  {
    id: "1",
    image: "/assets/1.png",
    desc: "This Beautifully Designed Project was made as an Job providing website for people in UAE.  ",
    livedemo: "https://www.alteeballc.co/",

    tech: "HTML, CSS, JS",
  },
  {
    id: "2",
    image: "/assets/2.png",
    desc: "This is Disney Clone Made with REACT JS ",
    livedemo: "https://merry-shortbread-41398d.netlify.app",
    github: "https://github.com/prajwal000/disneyclone",
    tech: "REACT, HTML, JS, CSS, FIREBASE",
  },
  {
    id: "3",
    image: "/assets/4.png",
    desc: "This is Burger  Hub an ecommerce site made with REACTJS. Fully Responsive",
    livedemo: "https://burgershop-hub.netlify.app",
    github: "https://github.com/prajwal000/Burger-Hub",
    tech: "REACT, HTML, JS, SCSS",
  },
  {
    id: "4",
    image: "/assets/9.png",
    desc: "This is Online kitchen beautifully designed  responsive made with REACTJS and firebase",
    livedemo: "https://sparkling-lamington-4b4f2f.netlify.app",
    github: "https://github.com/prajwal000/onlinekitchen",
    tech: "REACT, HTML, JS, FIREBASE, SASS",
  },
  {
    id: "5",
    image: "/assets/3.png",
    desc: "This is Movie Suggestor App made beautifully designed  site made with REACTJS ",
    livedemo: "https://gorgeous-scone-95335a.netlify.app",
    github: "https://github.com/prajwal000/moviesuggestor",
    tech: "REACT, HTML, JS ,SCSS",
  },
  {
    id: "6",
    image: "/assets/5.png",
    livedemo: "https://soft-granita-eff785.netlify.app",
    desc: "This is TO-DO  App made beautifully designed  site made with REACTJS ",
    github: "https://github.com/prajwal000/todo_app",
    tech: "REACT, HTML, JS, SCSS",
  },
  {
    id: "7",
    image: "/assets/7.png",
    desc: "This is Contact Manager  App made beautifully designed  site made with REACTJS ",
    livedemo: "https://creative-sprinkles-0c5d09.netlify.app",
    github: "https://github.com/prajwal000/contactmanager",
    tech: "REACT, HTML, JS, SCSS",
  },
  {
    id: "8",
    image: "/assets/8.png",
    desc: "This is Tourset a travel   App made beautifully designed  site made with REACTJS ",
    livedemo: "https://tubular-pithivier-fb66c6.netlify.app",
    github: "https://github.com/prajwal000/tourset",
    tech: "REACT, HTML, JS, SCSS",
  },
  {
    id: "9",
    image: "/assets/6.png",
    desc: "This is Shopo an ecommerce site made with REACTJS. ",
    livedemo: "https://resilient-cranachan-9dad68.netlify.app",
    github: "https://github.com/prajwal000/shopo",
    tech: "REACT, HTML, JS, SCSS",
  },
];
export default data;
